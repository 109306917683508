$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #48dacb; //
$COLFLAT3: #48dacb; //
$COL4: #48dacb; //
$COLFLAT4: #48dacb; //
$COL5: #646569; //
$COLFLAT5: #646569; //
$COL6: #8edced; //
$COLFLAT6: #8edced; //
$COL7: #8edced; //
$COLFLAT7: #8edced; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #646569; //
$COLFLAT9: #646569; //
$COL10: #646569; //
$COLFLAT10: #646569; //
$COL11: #919191; //
$COLFLAT11: #919191; //
$COL12: #adaeaf; //
$COLFLAT12: #adaeaf; //
$COL13: #646569; //
$COLFLAT13: #646569; //
$COL14: #646569; //
$COLFLAT14: #646569; //
$COL15: rgba(0,0,0,0); //
$COLFLAT15: #808080; //
$COL16: #646569; //
$COLFLAT16: #646569; //
$COL17: #e8e8e8; //Light Grey
$COLFLAT17: #e8e8e8; //Light Grey
$COL18: #fc6f8f; //
$COLFLAT18: #fc6f8f; //
$COL19: #e8e8e8; //
$COLFLAT19: #e8e8e8; //
$COL20: #ffb1be; //
$COLFLAT20: #ffb1be; //
$COL21: #f1f178; //
$COLFLAT21: #f1f178; //
$COL22: #646569; //
$COLFLAT22: #646569; //
$COL23: #ffffff; //
$COLFLAT23: #ffffff; //
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: #000000; //
$COLFLAT25: #000000; //
$COL26: #ffffff; //
$COLFLAT26: #ffffff; //
$COL27: #ffffff; //
$COLFLAT27: #ffffff; //
$COL28: #ffffff; //
$COLFLAT28: #ffffff; //
$COL29: #000000; //black
$COLFLAT29: #000000; //black
$COL30: rgba(255,255,255,0.502); //white 50%
$COLFLAT30: #c0c0c0; //white 50%
$COL31: rgba(142,220,237,0.8); //blue 80%
$COLFLAT31: #8bcad7; //blue 80%
$COL32: #d6d7d8; //light grey
$COLFLAT32: #d6d7d8; //light grey
$COL33: #009cde; //paypal blue
$COLFLAT33: #009cde; //paypal blue
$COL34: rgba(255,255,255,0.302); //white 30%
$COLFLAT34: #a6a6a6; //white 30%
$COL35: #f2f2f2; //light grey
$COLFLAT35: #f2f2f2; //light grey
$COL36: rgba(255,255,255,0.102); //white 10%
$COLFLAT36: #8d8d8d; //white 10%
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
