body { background-color: $COL2; }
body { color: $COL5; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL9;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL10;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 22px;

@media #{$medium-up} {
font-size: 32px;

}
}
h3 {
color: $COL11;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL12;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL13;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL14;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL16;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL15;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 30px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 30px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
p { padding-bottom: 1em; }
a {color: $COL4}
a:hover {color: $COL6}
/* Category highlight:37 */
.MES37 {
color: $COL2;
 }
.MES37 {
color: $COL2;
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: $COL2;
 }
 }
cite.MEC37{
color: $COL2;
}
/* Paypal Button:27 */
.MES27 {
background-color: $COL2;
color: $COL33;
border-width: 1px;
border-style: solid;
border-color: $COL33;
 }
/* Footer Menu:28 */
nav.me-Menu.MES28 {
& .menu-item.MEC28, & .menu-item.MEC28 > div.MEC28{ & > a.MEC28{color: $COL22;
text-transform: uppercase;
}
 &:hover > a.MEC28{color: $COL3;
}
 }
&.horizontal > .menu-item.MEC28 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC28 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC28 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC28 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 10px 0;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Footer social media links:29 */
.MES29 {
background-color: $COL17;
padding: 15px 0;

 }
.MES29 {
background-color: $COL17;
padding: 15px 0;

 }
a.MEC29 { color: $COL22;
&:hover { color: $COL3; }
 }
/* cart preview item separator:30 */
.MES30 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL17 transparent transparent transparent;}
& > hr {border-top-style: dashed;}
 }
/* add to cart:31 */
.MES31 {
background-color: $COL20;
color: $COL26;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
border-radius: 100px;
padding: 10px 30px;

 }
/* view more:32 */
.MES32 {
background-color: $COL19;
color: $COL25;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 12.8px;
};
 }
/* Cart Preview Content:33 */
.MES33 {
 }
.MES33 {
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: $COL22;
 }
h1.MEC33 { @media #{$large-up} { font-size: 32px; }; }
h2.MEC33 { @media #{$large-up} { font-size: 25.6px; }; }
h3.MEC33 { @media #{$large-up} { font-size: 24px; }; }
h4.MEC33 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC33 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC33 { @media #{$large-up} { font-size: 12.8px; }; }
 }
/* Cart Item:34 */
.MES34 {
color: $COL22;
 }
.MES34 {
color: $COL22;
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL22;
 }
 }
cite.MEC34{
color: $COL22;
}
/* Contact Form:35 */
.me-block.me-Form.MES35,  body.MES35 {background-color:transparent;
 color: $COL2;
 
 input[type='submit']{border-style: solid;background-color: $COL3;
&:hover {background-color: $COL7}
border-width: 0;
color: $COL2;
&:hover {color: $COL2}
padding: 10px 30px;

border-radius: 100px;
}
@include placeholder($COL22);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-color: $COL32;
background-color: $COL35;
color: $COL22;
}
::-webkit-input-placeholder { color: $COL22; opacity: 0.5;}
:-moz-placeholder {color: $COL22; opacity: 0.5;} 
::-moz-placeholder {color: $COL22; opacity: 0.5;}
:-ms-input-placeholder {color: $COL22; opacity: 0.5;}

  }
/* Hollow 1:38 */
.MES38 {
background-color: $COL1;
&:hover { background-color: $COL3;}
color: $COL3;
&:hover { color: $COL2;}
border-radius: 100px;
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 40px;

}
border-width: 1px;
border-style: solid;
border-color: $COL3;
&:hover { border-color: $COL1; }
 }
/* Home Slider:24 */
.MES24 {
& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL17;
border-radius: 50%;
border-style:solid;
border-width:3px;
@media #{$medium-up} {
border-width: 3px;
};
@media #{$large-up} {
border-width: 3px;};
&:hover{border-color: $COL17;
;}
background-color: $COL3;
background-clip: padding-box;
&:hover {background-color: $COL18;}
width:5px;
height:5px;
@media #{$medium-up} {
width:5px;
height:5px;
};
@media #{$large-up} {
width:5px;
height:5px;
};
&:hover{background-color: $COL18;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL17;
background-color: $COL18;
  }
}
 }
/* Grey Buttom:39 */
.MES39 {
background-color: $COL3;
color: $COL8;
&:hover { color: $COL2;}
border-radius: 100px;
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 40px;

}
 }
/* Text:40 */
.MES40 {
background-color: $COL1;
&:hover { background-color: $COL1;}
color: $COL29;
&:hover { color: $COL29;}
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 17.6px;
};
padding: 5px 10px;

 }
/* test:41 */
nav.responsive-menu {
.menu-item.MEC41{background-color: $COL3;
}
& .menu-item.MEC41, & .menu-item.MEC41 > div.menu-item-wrap{ & > a.MEC41, & > i{color: $COL2;
text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC41 { border:0;
border-color: $COL2;
border-style: solid;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 10px;}

& .sub-menu{.menu-item.MEC41{background-color: $COL19;
}
& .menu-item.MEC41, & .menu-item.MEC41 > div.menu-item-wrap{ & > a.MEC41, & > i{color: $COL2;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC41{background-color: $COL7;
}
& .menu-item.MEC41, & .menu-item.MEC41 > div.menu-item-wrap{ & > a.MEC41, & > i{color: $COL2;
text-align: left;
}
  }
}}

 }
/* Product White box:42 */
.MES42 {
background-color: $COL2;
padding: 0 10px;

border-width: 0 1px 1px 1px;
border-style: solid;
border-color: $COL32;
 }
.MES42 {
background-color: $COL2;
padding: 0 10px;

border-width: 0 1px 1px 1px;
border-style: solid;
border-color: $COL32;
 }
/* Blue Buttom:43 */
.MES43 {
background-color: $COL7;
color: $COL8;
&:hover { color: $COL2;}
border-radius: 100px;
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 40px;

}
 }
/* Hollow Grey:44 */
.MES44 {
background-color: $COL1;
&:hover { background-color: $COL22;}
color: $COL22;
&:hover { color: $COL2;}
border-radius: 100px;
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 40px;

}
border-width: 1px;
border-style: solid;
border-color: $COL22;
&:hover { border-color: $COL1; }
 }
/* Was Price:45 */
.MES45 {
color: $COL18;
 }
.MES45 {
color: $COL18;
h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: $COL18;
 }
 }
cite.MEC45{
color: $COL18;
}
/* Search Panel:46 */
.MES46 {
background-color: $COL2;
 }
.MES46 {
background-color: $COL2;
 }
/* Option Box:47 */
.MES47 {
padding: 3px;

border-width: 2px;
border-style: solid;
border-color: $COL35;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES47 {
padding: 3px;

border-width: 2px;
border-style: solid;
border-color: $COL35;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Cart Icon:25 */
.MES25 {
 & .cart-panel{  }
 color: $COL18;
font-size: 14px;
@media #{$medium-up} {
font-size: 16.8px;
}}/* cart preview:23 */
.MES23 {
background-color: $COL2;
border-style: solid;
border-color: $COL17;
border-width: 8px;
padding: 10px;

.MEC23 {background-color: $COL18;
color: $COL2;
padding: 5px 30px;

border-width: 1px;
border-style: solid;
border-color: $COL1;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
&:hover {background-color: $COL20;
color: $COL2;
}
}
 }
/* Secondary:2 */
.MES2 {
background-color: $COL3;
color: $COL23;
 }
/* Alternate:11 */
.MES11 {
background-color: $COL18;
color: $COL24;
 }
/* Secondary:3 */
.MES3 {
background-color: $COL3;
color: $COL23;
 }
.MES3 {
background-color: $COL3;
color: $COL23;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL23;
 }
 }
cite.MEC3{
color: $COL23;
}
/* Primary:4 */
.MES4 {
background-color: $COL7;
color: $COL8;
&:hover { color: $COL2;}
 }
/* Primary:5 */
.MES5 {
background-color: $COL7;
color: $COL8;
 }
.MES5 {
background-color: $COL7;
color: $COL8;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL8;
 }
 }
cite.MEC5{
color: $COL8;
}
/* footer line:6 */
.MES6 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL17 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: $COL22;
text-transform: uppercase;
}
 &:hover > a.MEC7{color: $COL7;
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL2;
border-style: dashed;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 10px 30px;}

}
& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC7.active { & > a{ color: $COL7;}
 }
&.horizontal .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL31;}}
&.vertical .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL31;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC7{background-color: $COL31;
 &:hover {background-color: $COL7}
}
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: $COL2;
}
 &:hover > a.MEC7{color: $COL2;
}
 }

}
}
 }
/* Checkout Right Panel:8 */
.MES8 {
background-color: $COL17;
padding: 15px;

 }
.MES8 {
background-color: $COL17;
padding: 15px;

h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL22;
 }
h1.MEC8 { @media #{$large-up} { font-size: 30px; }; }
h2.MEC8 { @media #{$large-up} { font-size: 24px; }; }
h3.MEC8 { @media #{$large-up} { font-size: 22.5px; }; }
h4.MEC8 { @media #{$large-up} { font-size: 18.75px; }; }
h5.MEC8 { @media #{$large-up} { font-size: 15px; }; }
h6.MEC8 { @media #{$large-up} { font-size: 12px; }; }
 }
/* category mask:9 */
.MES9 {
background-color: $COL36;
color: $COL2;
 }
.MES9 {
background-color: $COL36;
color: $COL2;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL2;
 }
 }
cite.MEC9{
color: $COL2;
}
/* Footer light grey:10 */
.MES10 {
background-color: $COL17;
padding: 15px;

 }
.MES10 {
background-color: $COL17;
padding: 15px;

 }
/* Footer medium grey:26 */
.MES26 {
background-color: $COL32;
color: $COL22;
font-size: 11.2px;
padding: 15px;

 }
.MES26 {
background-color: $COL32;
color: $COL22;
font-size: 11.2px;
padding: 15px;

 }
cite.MEC26{
color: $COL22;
font-size: 11.2px;
}
/* Alternate:12 */
.MES12 {
background-color: $COL18;
color: $COL24;
 }
.MES12 {
background-color: $COL18;
color: $COL24;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL24;
 }
 }
cite.MEC12{
color: $COL24;
}
/* footer line:22 */
.MES22 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL32 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Light:13 */
.MES13 {
background-color: $COL19;
color: $COL25;
 }
/* Light:14 */
.MES14 {
background-color: $COL19;
color: $COL25;
 }
.MES14 {
background-color: $COL19;
color: $COL25;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL25;
 }
 }
cite.MEC14{
color: $COL25;
}
/* Dark:15 */
.MES15 {
background-color: $COL20;
color: $COL26;
 }
/* Dark:16 */
.MES16 {
background-color: $COL20;
color: $COL26;
 }
.MES16 {
background-color: $COL20;
color: $COL26;
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: $COL26;
 }
 }
cite.MEC16{
color: $COL26;
}
/* Shade 1:17 */
.MES17 {
background-color: $COL21;
color: $COL27;
 }
/* Shade 1:18 */
.MES18 {
background-color: $COL21;
color: $COL27;
 }
.MES18 {
background-color: $COL21;
color: $COL27;
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL27;
 }
 }
cite.MEC18{
color: $COL27;
}
/* Shade 2:19 */
.MES19 {
background-color: $COL22;
color: $COL28;
 }
/* Shade 2:20 */
.MES20 {
background-color: $COL22;
color: $COL28;
 }
.MES20 {
background-color: $COL22;
color: $COL28;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL28;
 }
 }
cite.MEC20{
color: $COL28;
}
/* Product grey box:36 */
.MES36 {
background-color: $COL17;
padding: 0 10px;

border-width: 1px 0 0 0;
border-style: solid;
border-color: $COL2 transparent transparent transparent;
 }
.MES36 {
background-color: $COL17;
padding: 0 10px;

border-width: 1px 0 0 0;
border-style: solid;
border-color: $COL2 transparent transparent transparent;
 }
/* Black:21 */
.MES21 {
background-color: $COL29;
 }
.MES21 {
background-color: $COL29;
 }
/* Option Box (Selected):48 */
.MES48 {
padding: 3px;

border-width: 2px;
border-style: solid;
border-color: $COL20;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES48 {
padding: 3px;

border-width: 2px;
border-style: solid;
border-color: $COL20;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
