.item.masonry img{
  border: 1px solid #e8e8e8;
  padding: 5px;
  margin-top: 5px;
}

.site-main-menu {
  nav > .menu-item:last-child,
  nav > .menu-item:last-child > .menu-item{
    & > a {color: #fc6f8f !important;}
  }
}

