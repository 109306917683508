.category-wrap {
  .me-block.me-MultiCol {
    position: absolute;
    height: 100%;
    width: 100%;
    display:none;
    top: 0;
    left: 0;
  }
  .cat-title {
    position: absolute;
    h1, h2, h3, h4, h5, h6 {
      text-shadow: 2px 2px 4px #000000;
    }
  }
  &:hover {
    .me-block.me-MultiCol {
      display:flex;
    }
    .cat-title{ display :none;}
  }
}

#instafeed{
  display: flex;
  .item a{
    position: relative;
    padding: 0 10px;
    .mask{
      position: absolute;
      top:0;
      bottom:0;
      width:100%;
    }
    img{
      width: 100vw;
      @media screen and (min-width: 481px){
        width: calc(100vw / 2);
      }
      @media screen and (min-width: 640px){
        width: calc(100vw / 3);
      }
      @media screen and (min-width: 1024px){
        width: calc(100vw / 5);
      }
    }
    a:hover{
      .mask{background-color:rgba(0,0,0,0.2);}
    }
  }
}

.me-SearchSolrFilterFacet {
  .item.column[data-label="Bright"]
  {
    order: -4;
    text-transform: uppercase;
  }
  .item.column[data-label="Pastel"]{
    order: -3;
    text-transform: uppercase;
  }
  .item.column[data-label="Multicolour"]{
    order: -2;
    text-transform: uppercase;
  }
  .item.column[data-label="Black & White"] {
    order: -1;
    text-transform: uppercase;
  }
}

.site-filter {
  background-color: rgba(255,255,255,0.9);
  box-shadow: 0 5px 5px #aaaaaa;
  max-height: 0;
  max-width: 1170px;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  position: absolute;
  z-index: 9;
  @media #{$large-up} {
    box-shadow: none;
    border: 1px solid #e8e8e8;
    position: relative;
    z-index: 0;
  }
  &.active {
    max-height: 100vh;
    opacity:1;
    transition: all 0.5s ease;
  }
}

@media #{$small-only} {
  .SearchSolrFilterFacet{
    max-height: 0;
    overflow:hidden;
    &.show {
      max-height: 800px;
      transition: max-height 0.5s ease;
    }
  }
}

@media #{$medium-up} {
  .obj-fit {
    flex:1;
    display:flex;
    flex-direction: column;
    img {
      flex: 1;
      object-fit: cover;
      font-family: "object-fit: cover;";
      height: 100%;
    }
  }
}

.header-search {
  .me-PanelCol {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 84vw;
    z-index: 9;
    @media #{$medium-up} {
      width: 300px;
    }
    @media #{$large-up} {
      left: auto;
      right: 0;
    }
    &.active {
      display: block;
    }
  }
}

.me-CartDropdown {
  select {
    background-color: #ebebeb;
  }
}

.me-CartQty input {
  margin: 0 auto;
  width: 44px;
  height: 44px;
  text-align: center;
  @media #{$medium-up} {
    width: 53px;
    height: 53px;
  }
}

.arts-frame {
  position: absolute !important;
  box-shadow: 5px 5px 7px 0 rgba(0,0,0,0.20);
  width: 100%;
  top: 0;
  img{
    display: none;
  }
}

.hide-for-frontend {
  display: none;
}

.scale-frame img {
  transform: scale(0.88);
}

.me-CartPreview {
  margin-top: -32px;
}